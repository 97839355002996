import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineChatAlt2 } from 'react-icons/hi'
import { RiRobot2Line } from 'react-icons/ri'

import {
	Avatar,
	Box,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import queryClient from 'config/query-client'
import { sendGAEvent } from 'config/trackers/google-analytics'

import { useChatRoomMessage } from 'modules/assistants/hooks/chat-room/use-chat-room-message'
import { useFreeChatStore } from 'modules/assistants/store/free-chat-store'
import { UserAvatar } from 'modules/common/components'
import { useStringToColor } from 'modules/common/hooks'
import { Assistant } from 'modules/map/types/assistants'
import { useMe } from 'modules/user/hooks'
import { userKeys } from 'modules/user/query'

import { ConfirmExitChatModal } from './components/confirm-exit-chat-modal'
import { MessageInputWithTokenCounter } from './components/message-input-with-token-counter'
import { WarningTokenChatsModal } from './components/warning-tokens-chat-modal'

interface Message {
	id: string
	text: string
	sender: 'user' | 'assistant'
}

interface Props {
	isOpen: boolean
	onClose: () => void
	assistantData: Assistant
	chatRoomId: string
}

export const AssistantChatModal = ({
	isOpen,
	onClose,
	assistantData,
	chatRoomId
}: Props) => {
	const TOKENS_INITIAL_VALUE = 10000

	const [messages, setMessages] = useState<Message[]>([])
	const [message, setMessage] = useState('')
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const { user } = useMe()
	const messagesContainerRef = useRef<HTMLDivElement>(null)
	const { t } = useTranslation()
	const toast = useToast()
	const tokensInitialValue = useRef<number>(TOKENS_INITIAL_VALUE)
	const [tokens, setTokens] = useState(TOKENS_INITIAL_VALUE)
	const [, bgColor] = useStringToColor({ text: assistantData.name })
	const { onClose: onCloseFreeChat } = useFreeChatStore()

	const { mutate: patchChatRoomMessage, isLoading: isLoadingChatRoomMessage } =
		useChatRoomMessage()

	const {
		isOpen: isConfirmExitChatModalOpen,
		onOpen: onOpenConfirmExitChatModal,
		onClose: onCloseConfirmExitChatModal
	} = useDisclosure()
	const {
		isOpen: isWarningTokenChatsModalOpen,
		onOpen: onOpenWarningTokenChatsModal,
		onClose: onCloseWarningTokenChatsModal
	} = useDisclosure()

	const handleSendMessage = (isTokenRefilled?: string) => {
		textareaRef.current && (textareaRef.current.style.height = '40px')

		if (message.trim() === '') {
			setMessage('')
			return
		}

		const newUserMessage: Message = {
			id: Date.now().toString(),
			text: message,
			sender: 'user'
		}

		const currentTokens = tokens
		let currentMessage = message

		if (isTokenRefilled === 'true') {
			setTokens(tokensInitialValue.current)
			setMessages((previousMessages) => [...previousMessages, newUserMessage])
			setMessage('')

			patchChatRoomMessage(
				{
					chatRoomId,
					message: currentMessage.trim(),
					openWindow: true
				},
				{
					onSuccess: ({ data }) => {
						queryClient.invalidateQueries(userKeys.me())

						setTokens(data.available_tokens < 0 ? 0 : data.available_tokens)

						const newAssistantMessage: Message = {
							id: data.id,
							text: data.last_message.text,
							sender: 'assistant'
						}

						setMessages((previousMessages) => [
							...previousMessages,
							newAssistantMessage
						])

						currentMessage = ''
					},
					onError: (error) => {
						console.log('error on patchChatRoomMessage', error)
						setTokens(0)
						toast({
							title: t('errors:request.error.title'),
							description: t('errors:request.error.description'),
							status: 'error'
						})
					}
				}
			)

			return
		}

		if (currentTokens <= 0) {
			onOpenWarningTokenChatsModal()
			return
		}

		setMessages((previousMessages) => [...previousMessages, newUserMessage])
		setMessage('')

		patchChatRoomMessage(
			{
				chatRoomId,
				message: currentMessage.trim()
			},
			{
				onSuccess: ({ data }) => {
					setTokens(data.available_tokens < 0 ? 0 : data.available_tokens)

					const newAssistantMessage: Message = {
						id: data.id,
						text: data.last_message.text,
						sender: 'assistant'
					}

					setMessages((previousMessages) => [
						...previousMessages,
						newAssistantMessage
					])

					currentMessage = ''
				},
				onError: (error) => {
					console.log('error on patchChatRoomMessage', error)
					toast({
						title: t('errors:request.error.title'),
						description: t('errors:request.error.description'),
						status: 'error'
					})
				}
			}
		)
	}

	const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (isLoadingChatRoomMessage) return

		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			handleSendMessage()
			sendGAEvent('assistant_chat_send_message_enter_key_pressed')
		}
	}

	const getProgressTokensColor = () => {
		const percentage = (tokens / tokensInitialValue.current) * 100

		switch (true) {
			case percentage <= 20:
				return 'red'
			case percentage <= 40:
				return 'yellow'
			default:
				return 'green'
		}
	}

	useEffect(() => {
		if (messages.length > 0) {
			const lastMessage = messages[messages.length - 1]
			setTimeout(() => {
				if (lastMessage.sender === 'user') {
					messagesContainerRef.current?.scrollTo({
						top: messagesContainerRef.current.scrollHeight,
						behavior: 'smooth'
					})
				} else {
					const lastMessageElement = messagesContainerRef.current
						?.lastElementChild as HTMLElement
					if (lastMessageElement) {
						lastMessageElement.scrollIntoView({
							behavior: 'smooth',
							block: 'start'
						})
					}
				}
			}, 100)
		}
	}, [messages])

	useEffect(() => {
		if (!isOpen) {
			setMessage('')
			setTokens(tokensInitialValue.current)
		} else if (isOpen) {
			onCloseFreeChat?.()
		}
	}, [isOpen])

	return (
		<>
			<Modal
				isOpen={isOpen}
				size={['full', '3xl']}
				isCentered
				onClose={() => { }}
				closeOnOverlayClick={false}
				closeOnEsc={false}
			>
				<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
				<ModalContent
					h={['100%', '570px']}
					display='flex'
					flexDirection='column'
				>
					<ModalHeader>
						<Flex alignItems='center'>
							<Avatar
								size='md'
								bgColor={bgColor}
								icon={<Icon as={RiRobot2Line} fontSize='2xl' color='white' />}
							/>
							<Box ml='3'>
								<Text title={assistantData.name} fontWeight='bold'>
									{assistantData.name}
								</Text>
							</Box>
						</Flex>
					</ModalHeader>
					<ModalCloseButton
						mt={3}
						mr={1}
						onClick={onOpenConfirmExitChatModal}
					/>

					<ModalBody px={[0, 6]} pb={['-10px', 0]} flex='1' overflow='hidden'>
						<Box
							ref={messagesContainerRef}
							flex='1'
							overflowY='auto'
							p='4'
							bg='#F9FAFE'
							borderRadius='md'
							border='1px solid #E5E5E5'
							display='flex'
							flexDirection='column'
							h='100%'
						>
							{messages.length === 0 ? (
								<VStack
									color='gray.300'
									alignItems='center'
									justifyContent='center'
									spacing={4}
									flex='1'
								>
									<HiOutlineChatAlt2 size='50px' />
									<Text color='gray.500' textAlign='center' fontWeight='medium'>
										{t('assistants:modal.chat.empty')}
									</Text>
									<Text
										textAlign='center'
										fontSize='13px'
										mt='-8px'
										color='gray.400'
										w='full'
									>
										{t('assistants:modal.chat.empty-description')}
									</Text>
								</VStack>
							) : (
								<>
									{messages.map((msg, index) => (
										<Flex
											key={msg.id + index}
											p='3'
											borderRadius='md'
											mb='2'
											alignSelf='flex-start'
											maxW={['100%', '95%']}
										>
											<UserAvatar
												iconOnly
												borderWidth='1px'
												borderColor='gray.200'
												size='xs'
												mr={2}
												user={{
													username:
														msg.sender === 'user'
															? user?.name.trim() || ''
															: assistantData.name
												}}
											/>
											<Box>
												<Text fontSize='sm' fontWeight='semibold'>
													{msg.sender === 'user'
														? t('assistants:modal.chat.me')
														: assistantData.name}
												</Text>
												<Text
													whiteSpace='pre-wrap'
													wordBreak='break-word'
													overflowWrap='break-word'
													color='gray.400'
													fontWeight='medium'
												>
													{msg.text}
												</Text>
											</Box>
										</Flex>
									))}
								</>
							)}
						</Box>
					</ModalBody>
					<ModalFooter>
						<MessageInputWithTokenCounter
							message={message}
							setMessage={setMessage}
							handleKeyPress={handleKeyPress}
							textareaRef={textareaRef}
							tokens={tokens}
							tokensInitialValue={tokensInitialValue.current}
							getProgressTokensColor={getProgressTokensColor}
							handleSendMessage={handleSendMessage}
							isLoading={isLoadingChatRoomMessage}
						/>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<ConfirmExitChatModal
				isOpen={isConfirmExitChatModalOpen}
				onClose={onCloseConfirmExitChatModal}
				setMessages={setMessages}
				onCloseChatModal={onClose}
				chatRoomId={chatRoomId}
			/>
			<WarningTokenChatsModal
				isOpen={isWarningTokenChatsModalOpen}
				onClose={onCloseWarningTokenChatsModal}
				user={user}
				handleSendMessage={handleSendMessage}
				tokensInitialValue={tokensInitialValue.current}
			/>
		</>
	)
}
