import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Container,
	Flex,
	Image,
	Select,
	Stack,
	Text,
	useMediaQuery
} from '@chakra-ui/react'

import AmbevLogo from 'modules/common/assets/img/layout/ambev/logo.svg'
import AnimaLogo from 'modules/common/assets/img/layout/anima/anima-education.svg'
import BateriasMouraLogo from 'modules/common/assets/img/layout/bateriasmoura/logo.svg'
import CgeeLogo from 'modules/common/assets/img/layout/cgee/logo.svg'
import ChildhoodLogo from 'modules/common/assets/img/layout/childhood/logo.svg'
import CiaDeTalentosLogo from 'modules/common/assets/img/layout/ciadetalentos/logo.svg'
import EquatorialLogo from 'modules/common/assets/img/layout/equatorial/logo.svg'
import FariasBritoLogo from 'modules/common/assets/img/layout/fariasbrito/logo.svg'
import FdcLogo from 'modules/common/assets/img/layout/fdc/logo.svg'
import FundacaoItauLogo from 'modules/common/assets/img/layout/fundacaoitau/logo.svg'
import InstitutoAnimaLogo from 'modules/common/assets/img/layout/institutoanima/logo.svg'
import JnjLogo from 'modules/common/assets/img/layout/jnj/logo.svg'
import LuminaLogo from 'modules/common/assets/img/layout/lumina/logo.svg'
import LumosAcademyLogo from 'modules/common/assets/img/layout/lumosacademy/logo.svg'
import MagaluLogo from 'modules/common/assets/img/layout/magalu/logo.svg'
import MgcLogo from 'modules/common/assets/img/layout/magalucloud/logo.svg'
import MitSloanReviewLogo from 'modules/common/assets/img/layout/mitsloanreview/logo-secondary.svg'
import PcrLogo from 'modules/common/assets/img/layout/pcr/logo.svg'
import PoweredFooter from 'modules/common/assets/img/layout/powered-footer.svg'
import RvDigitalLogo from 'modules/common/assets/img/layout/rvdigital/logo.svg'
import SaebLogo from 'modules/common/assets/img/layout/saeb/logo.svg'
import SebraepbLogo from 'modules/common/assets/img/layout/sebraepb/logo.svg'
import SinergiaLogo from 'modules/common/assets/img/layout/sinergia/logo.svg'
import TdsLogo from 'modules/common/assets/img/layout/tds/logo.svg'
import Umuarama2050Logo from 'modules/common/assets/img/layout/umuarama2050/logo.svg'
import UmuaramaCdu from 'modules/common/assets/img/layout/umuarama2050/partners/cdu.svg'
import UmuaramaFdc from 'modules/common/assets/img/layout/umuarama2050/partners/fdc.svg'
import UmuaramaPrefeitura from 'modules/common/assets/img/layout/umuarama2050/partners/prefeitura.svg'
import StrateegiaLogo from 'modules/common/assets/img/strateegia-logo.svg'
import { DynamicLogo } from 'modules/common/components/logo/dynamic-logo'
import { useTenant } from 'modules/common/contexts/global-tenant-context'
import { setLanguage } from 'modules/common/utils/change-language'
import './full-page-layout-styles.scss'

type Props = {
	children: ReactNode
	logoAsLink?: boolean
}

const FullPageLayout = ({
	children,
	logoAsLink = true,
	...props
}: Props): JSX.Element => {
	const { t, i18n } = useTranslation()
	const { tenant, isTenantDefault } = useTenant()
	const [isLargerThan800] = useMediaQuery('(min-width: 1100px)')

	const images: { [key: string]: string } = {
		SINERGIA: SinergiaLogo,
		ANIMA: AnimaLogo,
		INSTITUTOANIMA: InstitutoAnimaLogo,
		TDS: TdsLogo,
		FDC: FdcLogo,
		MAGALU: MagaluLogo,
		MGC: MgcLogo,
		CIADETALENTOS: CiaDeTalentosLogo,
		JNJ: JnjLogo,
		UMUARAMA2050: Umuarama2050Logo,
		EQUATORIAL: EquatorialLogo,
		RVDIGITAL: RvDigitalLogo,
		PCR: PcrLogo,
		LUMINA: LuminaLogo,
		CGEE: CgeeLogo,
		BATERIASMOURA: BateriasMouraLogo,
		SAEB: SaebLogo,
		SEBRAEPB: SebraepbLogo,
		CHILDHOOD: ChildhoodLogo,
		FARIASBRITO: FariasBritoLogo,
		LUMOSACADEMY: LumosAcademyLogo,
		MITSLOANREVIEW: MitSloanReviewLogo,
		AMBEV: AmbevLogo,
		FUNDACAOITAU: FundacaoItauLogo
	}

	const getMarginBasedOnTenant = () => {
		return isTenantDefault(tenant) ? '0 auto auto auto' : 'auto'
	}
	return (
		<Box
			className={`fullPageLayoutWrap fullPageLayoutWrap-${tenant.toLowerCase()}`}
			{...props}
			p={[1, 6]}
			backgroundColor='screen'
		>
			{isTenantDefault(tenant) && (
				<Box
					w='full'
					mt={'auto'}
					display='flex'
					alignItems='center'
					justifyContent='center'
					as='header'
					pb={[3, 7]}
				>
					<DynamicLogo
						logoAsLink={logoAsLink}
						href='https://strateegia.digital/'
					>
						<Image
							src={StrateegiaLogo}
							alt='Strateegia'
							width={48}
							maxW='full'
						/>
					</DynamicLogo>
				</Box>
			)}

			{!isTenantDefault(tenant) && (
				<Box
					as='header'
					position={isLargerThan800 ? 'absolute' : 'initial'}
					margin={isLargerThan800 ? '10px 0 0 0' : 'auto auto 0'}
					top='15px'
					width='auto'
					pb={[3, 7]}
				>
					<DynamicLogo
						logoAsLink={logoAsLink}
						href={`https://${tenant.toLowerCase()}.strateegia.digital/`}
					>
						<Image
							src={images[tenant]}
							alt='Strateegia'
							width={isLargerThan800 ? 40 : ''}
							maxW='full'
						/>
					</DynamicLogo>
				</Box>
			)}

			<Container
				w={['none', 'lg']}
				margin={isLargerThan800 ? getMarginBasedOnTenant() : '0 auto auto'}
				bg={['none', 'white']}
				borderRadius={['none', 10]}
				boxShadow={
					isTenantDefault(tenant) ? ['none', '0px 0px 20px 2px #E5E5E5'] : []
				}
				style={
					!isLargerThan800 && !isTenantDefault(tenant)
						? { backgroundColor: 'white', borderRadius: 10 }
						: {}
				}
				maxW={isLargerThan800 ? 'container.md' : '95%'}
				width={'100%'}
			>
				{children}
				<Flex
					pb={6}
					as='footer'
					justify='space-between'
					align='center'
					px={[6, 7]}
					maxW={{
						base: '100%'
					}}
				>
					<Box>
						<Select
							minW={14}
							variant='flushed'
							border='none'
							value={i18n.language.slice(0, 2)}
							onChange={({ target }) => {
								setLanguage(target.value)
							}}
						>
							<option value='en'>EN</option>
							<option value='pt'>PT</option>
							<option value='es'>ES</option>
						</Select>
					</Box>
					<Text
						fontSize='xs'
						color='gray.300'
						display={isTenantDefault(tenant) ? 'block' : 'none'}
					>
						{t('login:copy')}
					</Text>
				</Flex>
			</Container>

			{tenant === 'UMUARAMA2050' && <UmuaramaPartners />}

			{!isTenantDefault(tenant) && (
				<Box
					w='full'
					display='flex'
					justifyContent='center'
					alignItems='center'
					as='footer'
					p={[3, 7]}
					mt={4}
				>
					<DynamicLogo
						logoAsLink={logoAsLink}
						href={`https://${tenant.toLowerCase()}.strateegia.digital/`}
					>
						<Image
							src={PoweredFooter}
							alt='Strateegia'
							width={isLargerThan800 ? 60 : ''}
						/>
					</DynamicLogo>
				</Box>
			)}
		</Box>
	)
}

const UmuaramaPartners = () => {
	return (
		<Stack
			direction={['column', 'row']}
			mt={4}
			gap={8}
			w='full'
			placeContent='center'
			alignItems='center'
		>
			<Image width={40} src={UmuaramaPrefeitura} />
			<Image width={28} src={UmuaramaCdu} />
			<Image width={24} src={UmuaramaFdc} />
		</Stack>
	)
}

export default FullPageLayout
