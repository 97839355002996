import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InfiniteData } from 'react-query'

import {
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	SimpleGrid,
	useBreakpointValue,
	VStack
} from '@chakra-ui/react'

import { AxiosResponse } from 'axios'

import { useAssistants } from 'modules/assistants/hooks/use-assistants'
import { Empty, ScrollBox } from 'modules/common/components'
import { IconMagnifyingGlass } from 'modules/common/components/icons'
import LoadingStrateegia from 'modules/common/components/loading'
import { useDebounce } from 'modules/common/hooks'
import { PageableResponse } from 'modules/common/types'
import { Assistant } from 'modules/map/types/assistants'
import { useMe } from 'modules/user/hooks'
import { User } from 'modules/user/types'

import { AssistantSimpleCard } from './assistant-simple-card'

export const MyAssistants = ({
	columnMode = false
}: {
	columnMode?: boolean
}) => {
	const { t } = useTranslation()
	const [search, setSearch] = useState('')
	const debouncedSearch = useDebounce(search, 300)
	const isMobile = useBreakpointValue({ base: true, md: false }) as boolean
	const [initComponent, setInitComponent] = useState(false)
	const { user } = useMe()

	useEffect(() => {
		setInitComponent(true)
	}, [isMobile])

	const {
		data: assistants,
		fetchNextPage,
		isLoading
	} = useAssistants({
		type: 'all',
		size: isMobile && !columnMode ? 3 : 20,
		query: debouncedSearch,
		enabled: initComponent,
		sortBy: 'createdAt',
		order: 'DESC'
	})

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) =>
		setSearch(e.target.value)

	if (isLoading) {
		return (
			<Flex w='100%' height='100%' alignItems='center' justify='center'>
				<LoadingStrateegia />
			</Flex>
		)
	}

	const isEmpty = !assistants?.pages[0].data.number_of_elements

	return (
		<>
			<InputGroup mb={6}>
				<InputLeftElement children={<IconMagnifyingGlass />} />
				<Input
					borderWidth='0'
					borderRadius='3xl'
					backgroundColor='gray.100'
					type='text'
					fontSize='md'
					placeholder={t('assistants:page.action.search.placeholder')}
					onChange={handleSearch}
				/>
			</InputGroup>
			{isEmpty ? (
				<Empty title={t('assistants:page.empty-search')} />
			) : (
				<ScrollBox
					variant={columnMode ? 'modal' : 'outside'}
					onReachBottom={isMobile ? () => { } : fetchNextPage}
				>
					<AssistantsMap
						assistants={assistants}
						user={user}
						columnMode={columnMode}
					/>
				</ScrollBox>
			)}
		</>
	)
}

const AssistantsMap = ({
	assistants,
	user,
	columnMode
}: {
	assistants:
	| InfiniteData<AxiosResponse<PageableResponse<Assistant>, any>>
	| undefined
	user?: User
	columnMode: boolean
}) => {
	const Container = columnMode ? SimpleGrid : VStack

	return (
		<Container columns={columnMode ? [1, 2] : undefined} spacing={4}>
			{assistants?.pages.map((page, index) => (
				<React.Fragment key={`page${index}`}>
					{page.data.content.map((assistant) => (
						<AssistantSimpleCard
							key={assistant.id}
							user={user}
							assistant={assistant}
						/>
					))}
				</React.Fragment>
			))}
		</Container>
	)
}
