import create from 'zustand'

interface FreeChatStore {
	onExperimentNow: boolean
	isLoading: Record<string, boolean>
	setOnExperimentNow: (value: boolean) => void
	setIsLoading: (assistantId: string, isLoading: boolean) => void
	onClose: any
	setOnClose: (value: any) => void
}

export const useFreeChatStore = create<FreeChatStore>((set) => ({
	onExperimentNow: false,
	isLoading: {},
	onClose: null,
	setOnClose: (value: any) => set({ onClose: value }),
	setOnExperimentNow: (value) => set({ onExperimentNow: value }),
	setIsLoading: (assistantId, isLoading) =>
		set((state) => ({
			isLoading: {
				...state.isLoading,
				[assistantId]: isLoading
			}
		}))
}))
