import fundacaoitauColors from './fundacaoitau'
import ambevColors from './ambev'
import mitsloanreviewColors from './mitsloanreview'
import lumosacademyColors from './lumosacademy'
import fariasbritoColors from './fariasbrito'
import childhoodColors from './childhood'
import sebraepbColors from './sebraepb'
import saebColors from './saeb'
import bateriasmouraColors from './bateriasmoura'
import cgeeColors from './cgee'
import luminaColors from './lumina'
import pcrColors from './pcr'
import rvdigitalColors from './rvdigital'
import equatorialColors from './equatorial'
import umuarama2050Colors from './umuarama2050'
import animaColors from './anima'
import ciadetalentosColors from './ciadetalentos'
import defaultColors from './default'
import fdcColors from './fdc'
import institutoAnimaColors from './institutoanima'
import jnjColors from './jnj'
import magaluColors from './magalu'
import mgcColors from './magalucloud'
import sinergiaColors from './sinergia'
import tdsColors from './tds'
import { TenantColors } from './types'

const tenantColors: { [key: string]: TenantColors } = {
	DEFAULT: defaultColors,
	SINERGIA: sinergiaColors,
	ANIMA: animaColors,
	INSTITUTOANIMA: institutoAnimaColors,
	TDS: tdsColors,
	FDC: fdcColors,
	MAGALU: magaluColors,
	MGC: mgcColors,
	CIADETALENTOS: ciadetalentosColors,
	JNJ: jnjColors
,
	UMUARAMA2050: umuarama2050Colors,
	EQUATORIAL: equatorialColors,
	RVDIGITAL: rvdigitalColors,
	PCR: pcrColors,
	LUMINA: luminaColors,
	CGEE: cgeeColors,
	BATERIASMOURA: bateriasmouraColors,
	SAEB: saebColors,
	SEBRAEPB: sebraepbColors,
	CHILDHOOD: childhoodColors,
	FARIASBRITO: fariasbritoColors,
	LUMOSACADEMY: lumosacademyColors,
	MITSLOANREVIEW: mitsloanreviewColors,
	AMBEV: ambevColors,
	FUNDACAOITAU: fundacaoitauColors
}

export default tenantColors
