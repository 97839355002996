import React from 'react'
import { useTranslation } from 'react-i18next'

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast
} from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import { useCloseChatRoom } from 'modules/assistants/hooks/chat-room/use-close-chat-room'

export const ConfirmExitChatModal = ({
	isOpen,
	onClose,
	setMessages,
	onCloseChatModal,
	chatRoomId
}: {
	isOpen: boolean
	onClose: () => void
	setMessages: React.Dispatch<React.SetStateAction<any[]>>
	onCloseChatModal: () => void
	chatRoomId: string
}) => {
	const { t } = useTranslation()
	const { mutate: closeChatRoom } = useCloseChatRoom()
	const toast = useToast()

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
				<ModalContent pt={4} borderRadius={'8px'}>
					<ModalHeader fontWeight={'bold'} fontSize={'md'}>
						{t('assistants:modal.chat.confirm-exit.title')}
					</ModalHeader>
					<ModalCloseButton mt={4} mr={3} />
					<ModalBody>
						<Text mb={0.5} color='gray.400' fontWeight='medium'>
							{t('assistants:modal.chat.confirm-exit.description')}
						</Text>
						<Text fontWeight='bold'>
							{t('assistants:modal.chat.confirm-exit.confirm')}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button fontSize='sm' mr={3} onClick={onClose}>
							{t('assistants:modal.chat.confirm-exit.action.cancel')}
						</Button>
						<Button
							fontSize='sm'
							colorScheme='pink'
							onClick={() => {
								setMessages([])
								onCloseChatModal()
								onClose()

								sendGAEvent('assistant_chat_exit_button_clicked')

								closeChatRoom(chatRoomId, {
									onError: (error) => {
										console.log('error on closeChatRoom', error)
										toast({
											title: t('errors:request.error.title'),
											description: t('errors:request.error.description'),
											status: 'error'
										})
									}
								})
							}}
						>
							{t('assistants:modal.chat.confirm-exit.action.confirm')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
